.verification-securit {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.verification-securit .div {
  background-color: #ffffff;
  height: 1024px;
  position: relative;
  width: 1440px;
}

.verification-securit .text-wrapper {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 64px;
  font-weight: 500;
  left: 481px;
  letter-spacing: 0;
  line-height: 64px;
  position: absolute;
  top: 403px;
  white-space: nowrap;
}

.verification-securit .p {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 29px;
  font-weight: 400;
  left: 463px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 515px;
  width: 513px;
}

.verification-securit .text-wrapper-2 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 29px;
  font-weight: 300;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 765px;
  width: 979px;
}

.verification-securit .overlap-group {
  background-color: #d9d9d9;
  height: 75px;
  left: 455px;
  position: absolute;
  top: 632px;
  width: 530px;
}

.verification-securit .rectangle {
  border: 5px solid;
  border-color: #ffffff;
  border-radius: 10px;
  height: 52px;
  left: 13px;
  position: absolute;
  top: 11px;
  width: 52px;
}

.verification-securit .v-rifiez-que-vous {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 29px;
  font-weight: 400;
  left: 108px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
}

.verification-securit .ellipse {
  height: 233px;
  left: 603px;
  object-fit: cover;
  position: absolute;
  top: 123px;
  width: 233px;
}

 /* Test */
 .test-checkbox{
  appearance: none;
  height: 50px;
  width: 50px;
  border-radius: 8px;
  background-color: white;
 }

 .test-checkbox:checked{
  background-color: #039BE5;
  border: 3px solid white;
 }