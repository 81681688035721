
/* Font family */
@font-face {
   font-family: 'Roboto';
   src: local('Roboto'), url('./font/Roboto/Roboto-Regular.ttf') format('truetype');
}
html, body, #root, #root>div {
   height: 100%;
   margin: 0;
   padding: 0;
   font-family: 'Roboto'!important;
   background-color: #f2f7fb;
}

/* input focus outline */
input, select{
   border: 1px solid #039ae5b2;
   height: 43px;
   border-radius: 12px;
   padding: 8px;
}

input[type="radio"]{
   height: 16px;
   width: 16px;
   appearance: none;
   background-color: white;
   border-radius:50%;   
}

input[type="radio"]:checked{
   background-color: #039BE5;
   border: 3px solid white;   
}

textarea{
   border: 1px solid #039ae5b2;
   border-radius: 12px;
   padding: 8px;

}

input:focus, select:focus, textarea:focus {
   border: 2px solid #039BE5;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

input:focus-visible, select:focus-visible, textarea:focus-visible {
   outline: none;
   /* Add any additional styling to remove the focus-visible indicator */
 }

.MuiCheckbox-root.Mui-checked {
   color: #039BE5!important;
}


.MuiCardHeader-root.css-1hwqbwg-MuiCardHeader-root{
   padding: 8px;
}
.MuiCardHeader-subheader.css-bttb1u-MuiTypography-root{
   color: white!important;
}

.MuiButton-root:hover{
   background-color: #039ae588!important;
}

.search-input {
   width: 0;
   transition: width 0.3s ease;
   border: none;
   border-radius: 8px;
 }

 .search-input:focus {
   border: 2px solid #039BE5;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
 }
 
 .expanded.search-input {
   width: 400px;
 }
 
 .search-icon {
   transition: transform 0.3s ease;
 }
 
 .expanded.search-icon {
   transform: rotate(90deg);
 }

 .hidden{
   color:#039BE5;
   background-color: #039BE5;
   border: none;
 }

 .MuiDialogTitle-root{
   background-color: #039BE5;
   color: white;
   width: 100%;
 }
 .MuiDialogTitle-root > button.MuiIconButton-root{
   color: white;
 }

 .css-8fu276-MuiModal-root-MuiDialog-root .MuiDialog-container .MuiPaper-root{
   overflow: hidden;
 }
 .MuiInputBase-input:focus{
   outline: none;
   border: none;
 }

 .css-14dk5ip-MuiButtonBase-root-MuiButton-root{
   color: #039BE5!important;
 }
 .css-sb6r6u-MuiButtonBase-root-MuiIconButton-root{
   color: white!important;
 }

 .css-18u1r1h-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
   color: #039BE5!important;
 }
 a{
  color: #f8b64d;
 }



 @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
       font-size:80px;
       }
       
       .link_404{      
  color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
  .contant_box_404{ margin-top:-50px;}